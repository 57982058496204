import { BrowserRouter, } from "react-router-dom";
import { About, Contact, Experience, Hero, Navbar, Tech, StarsCanvas } from './components'
import { Grid } from "@mui/material";
import Loader from "./components/Loader";

function App() {
  return (
    <BrowserRouter>
    <Grid container
    position="relative"
    direction="column"
    overflow="hidden"
    width="100%"
    height="auto"
    // display="flex"
    // backgroundColor="brown"
    className="flex z-0 bg-primary w-full"
    >
      <Grid item container
      className="bg-hero-pattern bg-cover "
      position="relative"
      
      // width="unset"
      // className="relative z-0"
      // minHeight="800px"
      // maxHeight="unset"
      height="100%"
      // wrap="wrap"
      // backgroundColor="pink"
      // display="block"
      >
      <Navbar/>  
      <Hero/>

      {/* <Loader/> */}

      </Grid>
 {/* <Loader/> */}

      <Grid item container
      position="relative"
      direction="column"
      height="unset"
      // backgroundColor="brown"
     
      // className="relative z-0"
      // display="block"

      >
         {/* <Navbar/>   */}
    
      <About/>
      
      <Experience />
      {/* <Tech />  */}

      {/* 
      
      <Works />
      <Feedbacks /> */}
      </Grid>

      <Grid item container
      position="relative"
      // backgroundColor="brown"
      display="block"
      // width="100%"
      // paddingRight="20px"
      // marginRight="20px"
      
      //  className="relative z-0"
       >
        <Contact />
        
       </Grid>


       <StarsCanvas />
    </Grid>
    </BrowserRouter>
  );
}

export default App;

