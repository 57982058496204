import React, { Suspense, useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Preload, SpotLight, useGLTF} from '@react-three/drei'
import CanvasLoader from '../Loader'
import { Grid, useMediaQuery } from '@mui/material'

// import { angleToRadians } from "../../utils/angle"


const Computers = () => {

   
    const computer = useGLTF('/desktop_pc/scene.gltf') 

    const matches = useMediaQuery(`(max-width: 500px)`)
  //  const [isMobile, setisMobile] = useState(false)
  //  console.log(isMobile)
  //  const pointLight = new THREE.PointLight(0xffffff)

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia(
  //     ('min-width : 500px')
  //     );
    
  //     setisMobile(mediaQuery.matches)

  //     const handleMediaQueryChange = (event) => {
  //       setisMobile(event.matches);
  //     }

  //     return () => {
  //     mediaQuery.addEventListener('change',
  //     handleMediaQueryChange);
  //     }
    
  // }, [])
  

  return (
    <Grid container item
    position="relative"
    overflow="hidden"
    display="flex"
    height="100%"
    // minWidth="100%"
    // maxWidth="unset
    // paddingRight="10px"
    // paddingLeft="20px"
    // minWidth="300px"
    // maxWidth="unset"
    // paddingBottom="200px"
    className='w-full'
    // backgroundColor="green"
    >

    <Canvas 
      frameloop='demand'
      shadows
      camera={{ position: [20,3,5], fov: 25}}
      gl={{ preserveDrawingBuffer: true }}
    
      // className='bg-purple-500 '
    // height="300px"
    // className='bg-red'
          // className='relative'
          // frameloop='demand'
          // shadows
          // gl={{ preserveDrawingBuffer: true }}
          // camera={{ position: [2,3,5], fov: 2}}
              // className='h-20'
              // height="1000px"
    >

      {/* <Html>
        <div>
          Hello WORLD
        </div>
      </Html> */}
      <Suspense fallback={<CanvasLoader/>}>
      <OrbitControls enableZoom={false} 
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2}
        />
        <>

          <mesh>
            {/* <sphereGeometry args={[1,32,32]} />
            <meshStandardMaterial color="#ffffff" /> */}
            
            <hemisphereLight 
            intensity={0.15}
            groundColor="black"
            />
            <pointLight 
            intensity={20}
            position={[4,1.8,-3]}
            // castShadow
            />
            {/* <SpotLight
              position={[0,8,0]}
              // angle={3}
              // penumbra={1}
              intensity={10}
              // castShadow
            /> */}
            <ambientLight 
            // args={["white", 1]} 
            intensity={1}
            />

            
            {/* <Media query={`(min-width: 400px)`}> */}
            <primitive
            object={computer.scene}
            scale={matches ? 0.7 : 0.75}
            position={matches ? [-5, .8, -1.6] : [8, -1, .5]}
            // position={[1, -.5, -.8]}
            rotation={[-0.01, -.2, -.2]}
            className="z-20"
            >
            </primitive>
            {/* </Media> */}
            {/* </pointLight> */}
            {/* </hemisphereLight> */}
            {/* </spotLight> */}
          </mesh>

  
        </>
      </Suspense>
      <Preload all />
    </Canvas>

     


    </Grid>

  )
    }

// const ComputersCanvas = () => {
//   return (
//     <Canvas
//       frameloop='demand'
//       shadows
//       camera={{ position: [20,3,5], fov: 25}}
//       gl={{ preserveDrawingBuffer: true }}
//     >

//       <Suspense fallback={<CanvasLoader />}>
//         <OrbitControls enableZoom={false} 
//         maxPolarAngle={Math.PI / 2}
//         minPolarAngle={Math.PI / 2}
//         />

//         <Computers />

//       </Suspense>

//       <Preload all />

//     </Canvas>
//   )
// }

// ComputersCanvas()

export default Computers