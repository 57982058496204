import React from "react"
import { Tilt } from  'react-tilt'
import { motion } from 'framer-motion'

import { styles } from '../styles';
import { services } from '../constants'
import { fadeIn, staggerContainer, textVariant } from '../utils/motion';
import { Grid } from "@mui/material";
import { SectionWrapper } from "../hoc";



const About = () => {


  const ServiceCard = ({index, title, icon}) => {
    return (
      // <p>
      //   {title}
      // </p>
      // Adding amination to a component upon view

      <motion.section
      variants={staggerContainer()}
      initial="hidden"
      whileInView="show"
      viewport={{once: false, amount: 0.5}}
      >
 

   
      <Tilt 
      className="xs:w-[250px] w-full"
      >
        
        <motion.div
          variants={fadeIn("right","spring", 0.5, index, 0.75)}
          className="rounded-[20px] green-pink-gradient p-[1px]  shadow-card"
          >
            
            <div
            // options={{

            //   max: 450,
            //   scale: 100,
            //   speed: 1 
            // }}
            className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[260px] flex justify-evenly items-center flex-col"
            >
              
              <img src={icon} alt={title}
              className="w-16 h-16 object-contain"
              />
              <Grid container 
              position="relative"
              display="flex"
              // backgroundColor="red"
              justifyContent="center"
              alignItems='center'
              wrap="nowrap"
              width="180px"
              // classname="flex  justify-center font-bold text-center text-white text-[20px]"
              >
                {title}
              </Grid>

            </div>
          
          </motion.div>

      </Tilt>
      </motion.section>
    )
  }
  
  return (
    <motion.section
      variants={staggerContainer()}
      initial="hidden"
      whileInView="show"
      viewport={{once: false, amount: 0.25}}
      >

  <Grid container
  paddingLeft="30px"
  paddingRight="30px"
  paddingBottom="95px"

  // backgroundColor="brown"
  >
    <motion.div variants={textVariant()}
    // className=" bg-slate-600"
    >
      <p 
      className={`${styles.sectionHeadText}`}
      >
        Introduction
      </p>
      <h2 className={styles.sectionHeadText}
      >
        Overview.
      </h2>

    </motion.div>

    <motion.p
    //  Fuor paramters for FadeIn, direction, type, delay, duration of animation
      variants={fadeIn("","",0.1,1)}
      className="mt-4 text-secondary text=[17px] max-w-3x1 leading-[30px]"
      >
        I am a skilled software developer with experience in Javascript and Python, and expertise in frameworks like React, Node.js, LangChain and Three.js. My most valued skill would be how adapatable and creative I am. Lets work together to bring ideas to life!
      </motion.p>

      <Grid container
      position="relative"
      marginTop="60px"
      display="flex"
      // backgroundColor="grey"
      justifyContent="center"
      gap="20px"


      // className="mt-20 flex flex-wrap gap=20"
      >
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {... service}/>
        ))}
      </Grid>
 </Grid>
 </motion.section>
  );
};

export default SectionWrapper(About, "about")
