import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import { motion } from 'framer-motion'
import { styles } from '../styles';
import { textVariant } from '../utils/motion';
import { SectionWrapper } from "../hoc";

import 'react-vertical-timeline-component/style.min.css'
import { experiences } from '../constants';
import { Grid } from '@mui/material';

const ExperienceCard = ({ experience }) => {

  return (
  <VerticalTimelineElement
  contentStyle={{background: '#1d1836', color: '#fff'}}
  // date={experience.date} 
  iconStyle={{ background: experience.iconBg }}
  icon={
    <div>
      <img
        src={experience.icon}
        alt={experience.company_name}
        className='w=[60%] h-[60%] object-contain'
      >
      </img>
    </div>

  } 
  >
    <div
    className='space-y-0'
    >
      <h3 className='text-white text-[24px] font-bold'>
        {experience.title}
      </h3>
      <p 
      className='text-secondary text-[16px] font-semibold '
      >
        {experience.company_name}
      </p>
    </div>

    <ul
    className='mt-5 list-disc ml-5 space-y-2'
    >
      {experience.points.map((point, index) => (
      <li
        key={`experience-point-${index}`}
        className='text-white-100 text-[14px] pl-1 tracking-wider'
      >
        {point}
      </li>
      ))}
    </ul>

    <a href='https://www.gateleak.com'>
    <motion.button
    className=' w-full h-[95px] hover: cursor-pointer'
        whileHover={{scale : 1.2}}
        whileTap={{scale: 0.9}}
    >
      
      Visit GateLeak.com
      
      

    </motion.button>
    </a>
   
    {/* {experience.date} */}
  </VerticalTimelineElement>
  )
}

const Experience = () => {
  return (
    <>
    <Grid container
    position="relative"
    direction="column"
    display="flex"
    // justifyContent="flex-start"
    alignItems="center"
    // gap="0px"
    // backgroundColor="red"
    // paddingTop="95px"
    paddingLeft="20px"
    paddingRight="30px"
    paddingBottom="95px"
    >
    <motion.div variants={textVariant()}
    // className=" bg-slate-600"
    >
      <p 
      className={`${styles.sectionSubText}`}
      >
        What have I done so far
      </p>
      <h2 className={`${styles.sectionHeadText} `}
      >
        Dev Experience.
      </h2>

    </motion.div>

    <div className='mt-10 flex flex-col'
    >
      <VerticalTimeline>
        {experiences.map((experience, index) => (
          <ExperienceCard key={index} experience={experience} />
        ))}

      </VerticalTimeline>
    </div>
    </Grid>
    </>
  )
}

export default SectionWrapper(Experience, "experience")