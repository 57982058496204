import React from 'react'
import {motion} from 'framer-motion'
import { styles } from '../styles'
import { ComputersCanvas } from './canvas'
import { Grid, useMediaQuery } from '@mui/material'


const Hero = () => {

  const media = useMediaQuery('(min-width:450px)')
  

  return (

    <Grid container
    position="relative"
    width="100%"
    // backgroundColor="pink"
    // overflow="hidden"
    paddingLeft="20px" 
    paddingRight="20px"
    paddingTop="100px"
    height="100vh"
    // backgroundColor="brown"
    direction="column"
    className='h-screen'
    wrap="nowrap"

    >

     <Grid container item
        position="relative"
        // direction="column"
        // backgroundColor="blue"
        paddingTop="20px"
        paddingLeft="10px"
        minHeight="auto"
        
        maxHeight="180px"
        
        width="100%"
        display="flex"
        wrap='nowrap'
        //  alignItems="center"
        //  justifyContent="flex-start"
        //  justifyItems="center"

     
        // className='items-center'
     >
      {/* Dot and Line*/}
      
      <Grid container 
      position="relative"
      direction="column"
      width="auto"
      paddingRight="10px"
      className='flex flex-col items-center'>

      <div className='w-5 h-5 rounded-full bg-[#915eff] absolute'/>
      <div className='w-1 sm:h-80 h-40 violet-gradient'/>

      </Grid>

      <Grid container item
      // className='bg-green-500'
      position='relative'
      // justifyContent="center"
      // alignItems="flex-start"
      gap="0"
      height="150px"
      display="flex"
      top="-20px"
      paddingLeft="15px"
      >
        
        <h1 
        className={`${styles.heroHeadText} text-white w-full text space-y-0 `}
      
        >
          Hi, <span
          className={`text-[#915eff] text-[${media ? "25px" : "20px"}]`}
          > {`I'm Just Your Friendly Neighborhood Developer`} </span>
        </h1>

        <p
        className="text-white-100 w-full "
        >
          I develop 3D visuals, <br className='xs:block sm:block'/> user interfaces, web and mobile applications. Try moving the computer below!
        </p>
      </Grid>

     </Grid>

      <Grid container item xs={12}
      position="relative"
      // direction="column"
      // paddingRight="20px"
      // width="100%"
      // wrap='nowrap'
      // className='z-0'
      // backgroundColor="red"
      // display="block"
      // minHeight="unset"
      >

        <Grid container item
        position="relative"
        // paddingTop="0px"
        // paddingRight="20px"
        // width="unset"
        height="100%"
        // minHeight="500px"
        // maxHeight="100%"
        // backgroundColor="grey"
        className=' xs:pt-[20px]'
        // display="flex"
        // overflow="hidden"

        >
          <ComputersCanvas />
        </Grid>

        
        <Grid container item
        // backgroundColor="yellow"
        // bottom="20px"
        sx = {{
          bottom:{
            xs: 140,
            sm: 20,
            md: 20,
            lg: 20,
            xl: 20,

          }
        }}
        direction="column-reverse"
        // display="flex"
        // alignItems="flex-end"
         className='absolute w-full flex justify-center items-center '
          // href="#about"
        >
          <Grid>
          Scroll Down
          </Grid>
          <a href="#about"
          className='z-10'
          >
          
         
            <Grid container 
            // href="#about"
          // onClick={() => {
          //   navigate('#about')
          // }}
          // paddingBottom="40px"
            // backgroundColor="red"
            position="relative"
            className='w=[35px] h-[64px] rounded-3xl border-4 border-secondary 
            flex justify-center items-start p-2'
            // backgroundColor="red"
            >
              

              <motion.div
                animate={{
                  y: [0, 24, 0]
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  repeatType: 'loop'
                }}
                className='h-3 w-3 rounded-full bg-secondary mb-1'
                />
                
            </Grid>
            </a>
           
          
        </Grid>
    

        

      </Grid>
      
     

      

    
      </Grid>
  )
}

export default Hero