import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import { motion } from 'framer-motion'
import { SectionWrapper } from '../hoc';
import { styles } from '../styles';
import { slideIn } from '../utils/motion';
import { EarthCanvas } from './canvas';
import emailjs from '@emailjs/browser'


// template_y610pyj




const Contact = () => {

  const matches = useMediaQuery('(min-width:750px)');
  
  const formRef = React.useRef();
  const [form, setForm] = React.useState({
    name: '',
    email: '',
    message: "",
  })

  const [loading, setLoading] = React.useState(false)

  const handleChange = (e) => {
    const {name, value} = e.target;

    setForm({ ...form, [name]: value})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    

    try {
      emailjs.send(
        process.env.REACT_APP_service,
        process.env.REACT_APP_template,
        {
          from_name : form.email,
          to_name: 'Obi',
          from_email: form.email,
          to_email: "ohbless.ware@gmail.com",
          message: form.message       
        }, 
        process.env.REACT_APP_key
        )
        .then(() => {
          setLoading(false);
          alert("Thank you. I will get back to you as soon as possible.");
    
          setForm({
            name: "",
            email: "",
            message: "",
          })})
      
    } catch (error) {
      
        setLoading(false)
        console.log(error)
        alert('Something went wrong.')
      
    }

    
      
      
      
    

  }




  return (
    
    <Grid container item
    // backgroundColor="brown"
    display="flex"
    // gap="10px"
    gap="0px"
    // rowGap="0px"
    // columnGap="0px"
    // spacing="0px"
    // overflow="scroll"
    wrap="nowrap"
    position="relative"
    direction={matches ? "row" : "column-reverse"}
    width="100%"
    // justifyContent="center"
    alignItems="center"
    padding=" 20px 0px 40px 0px"
    // paddingRight="30px"
    // width="100vw"
    // marginRight="100px"
    // className={`

    //     //  xs:flex-col-reverse
    //     //  sm:flex-col-reverse
    //     //  md:flex-row
    //     //  lg:flex-row

         
    //     `
    //   }

    // sx={{
    //   direction:{
    //     xs:'column-reverse',
    //     sm:'column-reverse',
    //     md:'column-reverse',
    //     lg:'row',
    //     xl:"row",
    //   }
    // }}
    
   
    // className='xs:flex-col'
    // className='xl:mt-12 xl:flex-row'
    >

        <Grid container item
        // xs={5}
        position="relative"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        
        paddingLeft="50px"
        // marginRight="20px"
        paddingRight="50px"
        
        minWidth="60%"
        maxWidth="450px"
        
        // backgroundColor="yellow"
        
        
        
        >
      <motion.div
      variants={slideIn('left','tween', 0.2, 1)}
      
      className='flex-[075] bg-black-100 p-8 rounded-[10px] xs:w-[100vw]'
      > 
      
        <p className={styles.sectionSubText}>Get in touch</p>
        <h3 className={styles.sectionHeadText}>Contact.</h3>

        <form 
        ref={formRef}
        onSubmit={handleSubmit}
        className='mt-12 flex flex-col gap-8'
        >
          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'>
              Your Name
            </span>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder={`What's your name?`}
              className='bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'>
              </input>
          </label>
          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'>
              Your Email
            </span>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder={`What's your email?`}
              className='bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'>
              </input>
          </label>

          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'>
              Your Message
            </span>
            <textarea
              rows="7"
              name="message"
              value={form.message}
              onChange={handleChange}
              
              placeholder={`What do you want to say?`}
              className='bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'>
              </textarea>
          </label>

          <button
          type='submit'
          className='bg-tertiary py-3 px-8 outline-none w-fit font-bold text-white shawdow-md rounded-xl'
          >
            {loading ? 'Sending ...' : 'Send'}
          </button>

        </form>
        </motion.div>
        </Grid>
     

        
        <Grid container item
        position="relative"
        // xs={7}
        width="100%"
        height="100%"
        justifyContent="center"
        // backgroundColor="pink"
        paddingRight={matches ? "30px" : "0px"}
        // marginRight="50px"
        >
          {/* <Grid container item
          width="100px"
          height="50px"
          backgroundColor="red"
          
          // position="fixed"
          borderRadius="15px">
            {`Spin Me! :)`}

          </Grid> */}
        
        <motion.div
        variants={slideIn('right','tween', 0.2, 1)}
        className='xl:h-auto xl:flex-1 md:h-[450px] h-[350px] xs:w-[400px] lg:w-[600px] relative'
        >
          

          <EarthCanvas/>

         
          
        </motion.div>
        </Grid>

        
      
        
        
        


    </Grid>
    
  )
}

export default SectionWrapper(Contact, "contact")