import React from "react";
import { Link } from "react-router-dom";
// import { styles } from '../styles'
import { navLinks } from '../constants'
import { logo, menu, close } from "../assets";
import { Grid } from "@mui/material";


const Navbar = () => {
  const [active, setActive] = React.useState("");
  const [toggle, setToggle] = React.useState(false);
  // const [scrolled, setScrolled] = useState(false);
  return (


    <Grid item container 
    position="fixed"
    direction="row"
    display="flex"
    paddingLeft="10px"
    paddingRight="30px"
    paddingTop="10px"
    paddingBottom="10px"
    
    
    // height="auto"
    // width="100%"
    // direction="column"
    wrap="nowrap" 
  
    // backgroundColor="blue"
    className={`z-20 bg-primary`}
    >


    {/* <nav
    // className={`
    // ${styles.paddingX} w-full flex items-center py-5 fixed top-0 z-20 bg-primary
    // `}
    > */}
    
      <Grid item container
      position="relative"
      // width="auto"
      // height="100%"
      // maxWidth="unset"
       direction="row"
        // wrap="nowrap"
        // backgroundColor="green"
        className="flex items-center"
        // display="flex"
      >
        <Link
        to="/"
        className="flex items-center gap-1"
        // position="relative"
        onClick={()=> {
          setActive("");
          window.scrollTo(0,0);
        }}>
          {/* NAV */}
          <img src={logo} alt="logo"
          width="50px"
          height="50px"
          
           />

          <p
          className="text-white text-[16px] flex items-center justify-center"
          > 
          Obi 
          <span
          className="md:block hidden"
          >
          &nbsp; | Web Portfolio
          </span>
          </p>


        </Link>
      </Grid>


      <Grid item container
      position="relative"
      
      justifyContent="flex-end"
      
      // display="flex"
        // direction="row"
        // wrap="nowrap"
        // backgroundColor="red"
        className="flex items-center"
      >
        <ul 
        
        // backgroundColor="green"
        className="list-none hidden xs:flex sm:flex md:flex flex-row xs:gap-5 relative "
        >
          {navLinks.map((link) => (
            <li
            key={link.id}
            className={`${
              active === link.title 
              ? "text-white"
              : "text-secondary"
            } hover:text-white text-[18px] font-medium cursor=pointer`}
            >
              <a href={`#${link.id}`}> 
              {link.title}
              </a>
            </li>
          ))}
          
        </ul>
      </Grid>

      <Grid item
      display="flex"
      position="relative"
      alignItems="center"
      width="180px"
      justifyContent="flex-end"
      // backgroundColor="red"
      // className="sm:hidden flex flex-1 justify-end items-center"
      >
        <img 
        src={toggle ? close : menu}
        alt="menu"
        width="20px"
        height="20px"
        className="cursor-pointer"
        onClick={()=>{
          setToggle(!toggle)
        }}

        />
      </Grid>

        
  


      
     
    {/* </nav> */}
    </Grid>
    

  )

};

export default Navbar;
