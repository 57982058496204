import { Canvas } from '@react-three/fiber'
import React, { Suspense } from 'react'
import { OrbitControls, Preload, useGLTF } from '@react-three/drei'
import  CanvasLoader  from "../Loader"
import { Grid } from '@mui/material'



  const EarthCanvas = () => {   

    
    
      const earth = useGLTF('/planet/scene.gltf')
      // console.log("Earth GLT", earth)
      
                              
    

  return (
    <Grid container
    position="relative"
    display="flex"
    height="100%"
    // backgroundColor="red"
    >
    <Canvas
    shadows
    frameloop='demand'
    gl={{ preserveDrawingBuffer: true}}
    camera={{
      fov: 45,
      near: 0.1,
      far: 200,
      position: [-4, 3, 6]
     }}
    >
      <Suspense fallback={<CanvasLoader/>}>
        <OrbitControls 
        enableZoom={false}
        autoRotate
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2}
        />
         <primitive
      object={earth.scene}
      scale={3}
      position-y={0}
      rotation-y={0}
      />
        
      </Suspense>
    </Canvas>
    </Grid>
  )}

export default EarthCanvas