import React from 'react'
import { useState, useRef, Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { PointMaterial, Points, Preload } from '@react-three/drei'
import * as random from "maath/random/dist/maath-random.esm"
import { Grid } from '@mui/material'



function StarsGroup(props) {
  const ref = useRef();
  // console.log("Current ref", ref.current)
  
  const sphere = random.inSphere(new Float32Array(5000), { radius: 1.2 });
  // console.log(sphere)

  useFrame((state, delta) => {
    ref.current.rotation.x += -delta / 10;
    ref.current.rotation.y += -delta / 15;
    // console.log(delta)
    // console.log("Current ref", ref.current)
  });

  return (
    <group 
    rotation={[0, 0, Math.PI / 4]}
    >
      <Points 
      ref={ref} 
      positions={sphere} 
      stride={3} 
      frustumCulled 
      {...props}
      >
        <PointMaterial 
        transparent 
        color="#f27272" 
        size={0.002} 
        sizeAttenuation 
        depthWrite={false}
        />
      </Points>
    </group>
  );
}

  

const StarsCanvas = (props) => {
  return (
   
    <Grid
    // backgroundColor="black"
    className='w-full h-auto absolute inset-0 z-[-1]'
    >    
      <Canvas
      camera={{ position : [0,0,1]}}
      >      
        <Suspense fallback={null}>
          <StarsGroup />          
        </Suspense>
        <Preload all />      
      </Canvas>
    </Grid>
 
  )
}

export default StarsCanvas